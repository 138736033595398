const deepCloneArray = initialArray => [...initialArray].map(element => {
    if (Array.isArray(element)) {
        return deepCloneArray(element);
    } else if (typeof element === 'object') {
        return deepCloneObject(element);
    } else {
        return element;
    }
});

const deepCloneObject = initialObject => {
    let clonedObject = {};
    for (const key in initialObject) {
        const property = initialObject[key];
        if (Array.isArray(property)) {
            clonedObject[key] = deepCloneArray(property);
        } else if (typeof property === 'object' && property !== null) {
            clonedObject[key] = deepCloneObject(property);
        } else {
            clonedObject[key] = property;
        }
    }
    return clonedObject;
};

export { deepCloneObject, deepCloneArray };